import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const ContactPage = ({ data }) => (
  <Layout>
    <SEO
      title="The Kalb Report | Contact Us"
      keywords={[`kalb`, `kalb report`, `the kalb report`]}
    />
    <section className="small-page-heading">
      <div className="container">
        <h1>Contact Us</h1>
      </div>
    </section>
    <section className="about-2 section bg-gray newsletter-section" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <p>
              Have a question about The Kalb Report? Send us an email, and we'll
              get back to you soon!
            </p>
            <hr />
            <p>
              Want to know about new events from The Kalb Report? Sign up for
              our newsletter to be among the first to know about new events!{" "}
            </p>
            <p>
              <a
                href="http://eepurl.com/gtAKFj"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to sign up
              </a>
            </p>
          </div>
          <div className="col-lg-6" style={{ textAlign: "center" }}>
            <h3>Thanks!</h3>
            <p>We received your message and will get back to you shortly.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContactPage;
